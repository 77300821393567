<template>
    <lassoLayout class="page-login">
        <div class="page_form_layout full_page">
            <v-container>
                <transition name="slide-fade" mode="out-in">
                    <div v-if="!showRegistration" key="login" class="wrap_form_layout">
                        <div class="form-card">
                            <h2 class="login">Log in!</h2>
                            <form action="#" class="form">
                                <div class="email">
                                    <label>Email</label>
                                    <input autofocus type="email" v-model="email" tabindex="1" v-on:keyup.enter="logIn">
                                    <div class="error-msg" v-if="showErrors && !$v.email.maxLength">Email must be 128 characters or less</div>
                                    <div class="error-msg" v-if="showErrors && $v.email.$error">Please enter a valid email address</div>
                                </div>
                                <div class="password">
                                    <div>
                                        <label>Password</label>
                                        <button type="button" class="show_password" @click="switchVisibility('password')"
                                                v-html="passwordType === 'password'? 'Show' : 'Hide'"></button>
                                    </div>
                                    <input :type="passwordType" v-model="password" tabindex="2" v-on:keyup.enter="logIn">
                                    <div class="error-msg" v-if="showErrors && !$v.password.maxLength">Password must be 128 characters or less</div>
                                </div>
                            </form>
                            <div class="message_error">
                                <template v-if="LOGIN_STATUS.success === false">
                                    {{LOGIN_STATUS.message}}
                                </template>
                            </div>

                            <button tabindex="3" v-if="email && password && !$v.email.$invalid && !$v.password.$invalid" 
                                    type="button" class="log_in c2a_btn" v-ripple @click="logIn" 
                                    :class="{'loading': loader}" :disabled="loader">
                                <v-progress-circular :width="2" indeterminate></v-progress-circular>
                                Log in
                                <span class="arrow">
                                    <span class="line_svg"></span>
                                    <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
                                        <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
                                    </svg>
                                </span>
                            </button>
                            <button v-else type="button" class="log_in c2a_btn" v-ripple disabled>Log in</button>
                            <div class="recovery_password">
                                <router-link :to="{name: 'forgot-password'}" tabindex="4">Recover password</router-link>
                            </div>
                            <a href="#" class="new_account" tabindex="5" @click.prevent="toggleForm">New Account</a>
                        </div>
                    </div>

                    <div v-else key="register" class="wrap_form_layout register">
                        <div class="form-card">
                            <h2 class="join_the_network">Sign up!</h2>
                            <div class="form">
                                <form action="#" class="form">
                                    <div class="email">
                                        <label for="">Email</label>
                                        <input autofocus type="email" v-model="email" tabindex="1" v-on:keyup.enter="signUp">
                                        <div class="error-msg" v-if="!$v.email.$error && !$v.email.maxLength">Email must be 128 characters or less</div>
                                    </div>
                                    <div class="password">
                                        <div>
                                            <label for="">Create Password</label>
                                            <button type="button" class="show_password" @click="switchVisibility('password')"
                                                    v-html="passwordType === 'password'? 'Show' : 'Hide'"></button>
                                        </div>
                                        <input :type="passwordType" v-model="password" @keyup="passwordOnKeyDown" tabindex="1" v-on:keyup.enter="signUp">
                                        <div class="error-msg" v-if="!$v.password.$error && !$v.password.maxLength">Password must be 128 characters or less</div>
                                    </div>
                                </form>
                            </div>
                            <div class="password_complexity">
                                <span class="label">Password must include:</span>
                                <div class="wrap_must_symbols">
                                    <div :class="[ {active: passComplexity.upperCase}, 'item_' ]">A-Z</div>
                                    <div :class="[ {active: passComplexity.lowerCase}, 'item_']">a-z</div>
                                    <div :class="[ {active: passComplexity.numbers}, 'item_']">123</div>
                                    <div :class="[ {active: passComplexity.nonWordChars}, 'item_']">!@#</div>
                                    <div :class="[ {active: password.length >= 8}, 'item_']">8+ Chars</div>
                                </div>
                            </div>
                            <div class="message_error" v-if="!loader">
                                {{REGISTER_ERROR_MESSAGE}}
                                <template v-if="REGISTER_STATUS !== 'success' && !REGISTER_ERROR_MESSAGE">
                                    {{ message }}
                                </template>
                            </div>

                            <button v-if="!$v.email.$invalid && passwordValid && !REGISTER_ERROR_MESSAGE && !message" 
                                    type="button" class="sign_up c2a_btn" 
                                    v-ripple @click="signUp" :class="{'loading': loader}" :disabled="loader">
                                <div v-for="n in 8" :key="n" class="bubble-stream"></div>
                                <v-progress-circular :width="2" indeterminate></v-progress-circular>
                                <template v-if="!loader">
                                    <span class="button-text">Sign Up</span>
                                    <span class="arrow">
                                        <span class="line_svg"></span>
                                        <svg width="7" height="10" viewBox="0 0 7 10" fill="none">
                                            <path d="M1.5 1.5L5 5L1.5 8.5" stroke="white" stroke-width="2" stroke-linecap="square"/>
                                        </svg>
                                    </span>
                                </template>
                            </button>
                            <button v-else type="button" class="sign_up c2a_btn" disabled>Sign Up</button>

                            <div class="already_account">
                                Already have an account?
                                <a href="#" @click.prevent="toggleForm">Log in!</a>
                            </div>
                        </div>
                    </div>
                </transition>
                <ComingSoonDialog v-if="showComingSoonDialog" v-model="showComingSoonDialog"></ComingSoonDialog>
                <AccountExistsDialog 
                    v-model="showAccountExistsDialog"
                    :loading="loader"
                    @login="attemptLoginWithCredentials"
                    @cancel="existingAccountCredentials = null">
                </AccountExistsDialog>
            </v-container>
        </div>
    </lassoLayout>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import lassoLayout from '@/layouts/lassoLayout.vue';
import {email, maxLength, minLength, required} from "vuelidate/lib/validators";

const ComingSoonDialog = () => import('@/components/dialogs/comingSoonDialog.vue');
const AccountExistsDialog = () => import('@/components/dialogs/accountExistsDialog.vue');

export default {
    name: 'login',
    components: {
        lassoLayout,
        ComingSoonDialog,
        AccountExistsDialog
    },
    props: {
        isRegister: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loader: false,
            showComingSoonDialog: false,
            showAccountExistsDialog: false,
            showRegistration: false,
            email: '',
            password: '',
            passwordType: 'password',
            passComplexity: {
                lowerCase: false,
                upperCase: false,
                nonWordChars: false,
                numbers: false
            },
            passwordValid: false,
            message: '',
            showErrors: false,
            existingAccountCredentials: null
        }
    },
    validations: {
        email: {
            email,
            required,
            maxLength: maxLength(128)
        },
        password: {
            required,
            maxLength: maxLength(128)
        },
    },
    watch: {
        email() {
            this.$v.$reset();
            this.message = '';
            this.$store.commit('SET_REGISTER_ERROR_MESSAGE', '');
        },
        password() {
            this.message = '';
            this.$store.commit('SET_REGISTER_ERROR_MESSAGE', '');
        },
        '$route'(to, from) {
            if (to.name === 'registration') {
                this.showRegistration = true;
            } else if (to.name === 'login') {
                this.showRegistration = this.$route.query.isRegister === 'true';
            }
        }
    },
    computed: {
        ...mapGetters(['AUTH_DATA', 'LOGIN_STATUS', 'NEED_LOGIN_REDIRECT_PATH', 'REGISTER_STATUS', 'REGISTER_ERROR_MESSAGE']),
    },
    mounted() {
        if (this.AUTH_DATA.accessToken) {
            this.$router.push({name: 'explore'});
        }
    },
    created() {
        this.showRegistration = this.isRegister || this.$route.query.isRegister === 'true';
        
        if (this.$route.name === 'registration') {
            this.showRegistration = true;
        }
    },
    methods: {
        ...mapActions(['LOGIN', 'CLEAN_EXPLORE_GRID_ALBUMS', 'GET_BASKET', 'REGISTER']),
        switchVisibility() {
            this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
        },
        toggleForm() {
            this.email = '';
            this.password = '';
            this.message = '';
            this.showErrors = false;
            this.showRegistration = !this.showRegistration;
            
            const routeName = this.showRegistration ? 'registration' : 'login';
            if (this.$route.name !== routeName) {
                this.$router.push({ name: routeName });
            }
        },
        passwordOnKeyDown() {
            this.passComplexity.nonWordChars = !!this.password.match(/[!@#$%^&*().,;:{}_+=<>?|~-]/);
            this.passComplexity.upperCase = !!this.password.match(".*[A-Z]");
            this.passComplexity.lowerCase = !!this.password.match(".*[a-z]");
            this.passComplexity.numbers = !!this.password.match(/[0-9]/);

            this.passwordValid = this.passComplexity.lowerCase === true && 
                               this.passComplexity.upperCase === true && 
                               this.passComplexity.nonWordChars === true &&
                               this.passComplexity.numbers === true &&
                               this.password.length >= 8;
        },
        logIn() {
            this.showErrors = true;
            this.$v.$touch();
            
            if (this.email && this.password && !this.$v.$invalid && !this.loader) {
                this.loader = true;

                const userData = {
                    email: this.email,
                    password: this.password
                };
                this.LOGIN(userData)
                    .then(() => {
                        if (this.LOGIN_STATUS.success === true) {
                            this.CLEAN_EXPLORE_GRID_ALBUMS();
                            if (this.AUTH_DATA.verify === true && this.AUTH_DATA.firstLogin === false) {
                                this.GET_BASKET();

                                let addAlbumToFavorites = localStorage.getItem('add_album_to_favorites');
                                if (addAlbumToFavorites) {
                                    this.$store.dispatch('ADD_ALBUM_TO_FAVORITES', {'music_release_id': addAlbumToFavorites})
                                        .then(() => {
                                            localStorage.removeItem('add_album_to_favorites');
                                        });
                                }
                                let addTrackToFavorites = JSON.parse(localStorage.getItem('add_track_to_favorites'));
                                if (addTrackToFavorites) {
                                    let params = {
                                        'song_id': addTrackToFavorites.song.id,
                                        'album_id': addTrackToFavorites.album.id
                                    }
                                    this.$store.dispatch('ADD_TRACK_TO_FAVORITES', params)
                                        .then(() => {
                                            localStorage.removeItem('add_track_to_favorites');
                                        });
                                }

                                this.$nextTick(() => {
                                    if (this.NEED_LOGIN_REDIRECT_PATH) {
                                        this.$router.push(this.NEED_LOGIN_REDIRECT_PATH.substring(1))
                                            .finally(() => this.loader = false);
                                        return;
                                    }
                                    this.$router.push(this.$route.query.redirect || '/')
                                        .finally(() => this.loader = false);
                                });
                                return;
                            }
                            if (this.AUTH_DATA.verify === false) {
                                this.$router.push({name: 'email-verification'})
                                    .finally(() => this.loader = false);
                                return;
                            }
                            if (this.AUTH_DATA.firstLogin === true && this.AUTH_DATA.verify === true) {
                                this.$router.push({name: 'profile-basics'})
                                    .finally(() => this.loader = false);
                                return;
                            }
                        }

                        if (this.LOGIN_STATUS.message === 'not_verified_email') {
                            this.$router.push({name: 'email-verification'})
                                .finally(() => this.loader = false);
                            return;
                        }
                        this.loader = false;
                    })
                    .catch(() => {
                        this.loader = false;
                    });
            }
        },
        async attemptLoginWithCredentials() {
            try {
                await this.LOGIN(this.existingAccountCredentials);
                if (this.LOGIN_STATUS.success === true) {
                    this.CLEAN_EXPLORE_GRID_ALBUMS();
                    if (this.AUTH_DATA.verify === true && this.AUTH_DATA.firstLogin === false) {
                        this.GET_BASKET();
                        this.$router.push(this.$route.query.redirect || '/');
                    } else if (this.AUTH_DATA.verify === false) {
                        this.$router.push({name: 'email-verification'});
                    } else if (this.AUTH_DATA.firstLogin === true && this.AUTH_DATA.verify === true) {
                        this.$router.push({name: 'profile-basics'});
                    }
                } else {
                    this.message = 'Login failed. Please try again.';
                }
            } catch (error) {
                this.message = 'Login failed. Please try again.';
            } finally {
                this.loader = false;
                this.showAccountExistsDialog = false;
                this.existingAccountCredentials = null;
            }
        },
        signUp() {
            this.showErrors = true;
            this.$v.$touch();
            
            if (this.email && this.password && this.passwordValid === true && !this.loader && this.password.length >= 8) {
                this.loader = true;

                const userData = {
                    email: this.email,
                    password: this.password,
                    address: 'Planet Earth',
                    fullName: this.email.split('@')[0],
                }
                this.REGISTER(userData)
                    .then(() => {
                        if (this.REGISTER_STATUS === 'success') {
                            this.$router.push({name: 'email-verification'});
                        } else if (this.REGISTER_ERROR_MESSAGE && this.REGISTER_ERROR_MESSAGE.toLowerCase().includes('exists')) {
                            // Store credentials and show dialog
                            this.existingAccountCredentials = {
                                email: this.email,
                                password: this.password
                            };
                            this.showAccountExistsDialog = true;
                        } else {
                            this.message = this.REGISTER_ERROR_MESSAGE || 'Registration failed. Please try again.';
                        }
                    })
                    .catch(err => {
                        if (err.response?.data?.['hydra:description']?.toLowerCase().includes('exists')) {
                            // Store credentials and show dialog
                            this.existingAccountCredentials = {
                                email: this.email,
                                password: this.password
                            };
                            this.showAccountExistsDialog = true;
                        } else {
                            this.message = err.response?.data?.['hydra:description'] || 
                                         err.response?.data?.message || 
                                         'Registration failed. Please try again.';
                        }
                    })
                    .finally(() => this.loader = false);
            } else {
                this.message = 'Please fill in all required fields correctly';
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/pages/_login.scss';
</style>
